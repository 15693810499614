<template>
  <div v-if="authUser" class="container-fluid overflow-hidden">
    <div class="row">
      <div class="col">
        <PageTitle :title="'Nutrition'"></PageTitle>

        <LoadingMessage v-if="isLoading"></LoadingMessage>

        <div v-if="!isLoading">
          <div v-if="nutrition">
            <!-- DOT NAV -->
            <div class="d-flex align-items-center justify-content-center">
              <IconButton
                @click="handleSwipeRight"
                :icon="'chevron-left'"
                class="btn me-3"
              ></IconButton>
              <div
                v-for="(day, dayIndex) in nutrition.dailyPlan"
                :key="`key-${dayIndex}`"
                :class="dayIndex === activeDayIndex ? 'is-active' : ''"
                class="dot"
              ></div>
              <IconButton
                @click="handleSwipeLeft"
                :icon="'chevron-right'"
                class="btn ms-3"
              ></IconButton>
            </div>

            <!-- CARDS -->
            <div
              v-touch:swipe.left="handleSwipeLeft"
              v-touch:swipe.right="handleSwipeRight"
              class="my-3"
            >
              <div
                v-for="(day, dayIndex) in nutrition.dailyPlan"
                :key="`key-${dayIndex}`"
              >
                <transition :name="transitionName">
                  <NutritionDayCard
                    v-if="dayIndex === activeDayIndex"
                    :dayIndex="dayIndex"
                    :mode="modeForDay(dayIndex)"
                    :data="dataForDay(dayIndex)"
                  ></NutritionDayCard>
                </transition>
              </div>
            </div>
          </div>

          <div v-else class="text-muted">
            No data.
            Please contact your coach to arrange a nutrition plan.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import { httpGet } from '@/core/http';

export default {
  name: 'Nutrition',
  components: {
    PageTitle: () => import('@/components/PageTitle'),
    LoadingMessage: () => import('@/components/message/LoadingMessage'),
    IconButton: () => import('@/components/button/IconButton'),
    NutritionDayCard: () => import('@/components/nutrition/NutritionDayCard'),
  },
  computed: {
    ...mapState('auth', ['authUser']),
  },
  methods: {
    async fetchNutrition() {
      this.isLoading = true;
      try {
        const { userId } = this.authUser;
        const res = await httpGet(`/nutrition/${userId}`);
        this.nutrition = res.data ? JSON.parse(res.data.data) : null;
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isLoading = false;
      }
    },
    modeForDay(index) {
      const { nutrition: { dailyPlan } } = this;
      return dailyPlan[index];
    },
    dataForDay(index) {
      const mode = this.modeForDay(index);
      const { nutrition: { deficit, maintenance, surplus } } = this;
      if (mode > 0) return surplus;
      if (mode < 0) return deficit;
      return maintenance;
    },
    handleSwipeLeft() {
      const { nutrition: { dailyPlan } } = this;
      let newIndex = this.activeDayIndex + 1;
      if (newIndex >= dailyPlan.length) {
        newIndex = 0;
      }
      this.transitionName = 'card-slide-left';
      this.activeDayIndex = newIndex;
    },
    handleSwipeRight() {
      const { nutrition: { dailyPlan } } = this;
      let newIndex = this.activeDayIndex - 1;
      if (newIndex < 0) {
        newIndex = dailyPlan.length - 1;
      }
      this.transitionName = 'card-slide-right';
      this.activeDayIndex = newIndex;
    },
  },
  data() {
    return {
      isLoading: false,
      nutrition: null,
      activeDayIndex: 0,
      transitionName: '',
    };
  },
  mounted() {
    const todayIndex = dayjs().format('d');
    this.activeDayIndex = parseInt(todayIndex, 10) === 0 ? 6 : todayIndex - 1;
    this.fetchNutrition();
  },
};
</script>

<style lang="scss" scoped>
.day:first-child {
  border-left: 0 !important;
}
</style>
